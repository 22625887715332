<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section" style="position: relative">
            <popup-comp></popup-comp>
            <div class="main">
<!--                <div class="main_img">-->
<!--                    <swiper :options="swiperOption">-->
<!--                        <swiper-slide>-->
<!--                            <img src="../../assets/images/main/ptn/main01.png" alt="" style="width: 100%">-->
<!--                        </swiper-slide>-->
<!--                        <swiper-slide>-->
<!--                            <img src="../../assets/images/main/ptn/main02.png" alt="" style="width: 100%">-->
<!--                        </swiper-slide>-->
<!--                        <swiper-slide>-->
<!--                            <img src="../../assets/images/main/ptn/main03.png" alt="" style="width: 100%">-->
<!--                        </swiper-slide>-->
<!--                        <div class="swiper-pagination" slot="pagination"></div>-->
<!--                    </swiper>-->
<!--                </div>-->

                <div class="main_img">
                    <img src="../../assets/images/main/ptn/main01.png" alt="" style="width: 100%">
                </div>
                <div class="main_img_mobile">
                    <img src="../../assets/images/main/ptn/main01_mobile.png" alt="" style="width: 100%">
                </div>

                <div class="sub_img">
                    <div class="sub">
                        <router-link to="/sports" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/sports.png" alt="">
                        </router-link>
                    </div>

                    <div class="sub">
                        <router-link to="/sports_inplay" tag="span">
                            <img src="../../assets/images/main/ptn/inplay.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/leisure/sutda" tag="span">
                            <img src="../../assets/images/main/ptn/mini.png" alt="">
                        </router-link>
                    </div>

                    <div class="sub">
                        <router-link to="/tgame365/tgame365highlow5s" tag="span">
                            <img src="../../assets/images/main/ptn/token.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/event" tag="span">
                            <img src="../../assets/images/main/ptn/event.png" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/notice" tag="span">
                            <img src="../../assets/images/main/ptn/notice.png" alt="">
                        </router-link>
                    </div>

                </div>
                <div class="sub_img">
                    <div class="sub">
                        <span style="cursor: pointer" @click="openGame(HONOR_EVO_GAMEID,HONOR_EVO_VENDOR)">
                            <img src="../../assets/images/main/ptn/evlution.png" alt="">
                        </span>
                    </div>

                    <div class="sub">
                        <span style="cursor: pointer" @click="openGame(HONOR_DREAM_GAMEID,HONOR_DREAM_VENDOR)">
                            <img src="../../assets/images/main/ptn/dream.png" alt="">
                        </span>
                    </div>
                    <div class="sub">
                        <span style="cursor: pointer" @click="openGame(HONOR_ASIAGAME_GAMEID,HONOR_ASIAGAME_VENDOR)">
                            <img src="../../assets/images/main/ptn/asia.png" alt="">
                        </span>
                    </div>

                    <div class="sub">
                        <span style="cursor: pointer" @click="openGame(HONOR_PRAGMATIC_GAMEID,HONOR_PRAGMATIC_VENDOR)">
                            <img src="../../assets/images/main/ptn/prag.png" alt="">
                        </span>
                    </div>
                    <div class="sub">
                        <span style="cursor: pointer" @click="openGame(HONOR_BOTA_GAMEID,HONOR_BOTA_VENDOR)">
                            <img src="../../assets/images/main/ptn/bota.png" alt="">
                        </span>
                    </div>
<!--                    <div class="sub">-->
<!--                        <span style="cursor: pointer" @click="openGame(HONOR_WM_GAMEID,HONOR_WM_VENDOR)">-->
<!--                            <img src="../../assets/images/main/ptn/wm.png" alt="">-->
<!--                        </span>-->
<!--                    </div>-->
<!--                    <div class="sub">-->
<!--                        <span style="cursor: pointer" @click="openGame(HONOR_DOWIN_GAMEID,HONOR_DOWIN_VENDOR)">-->
<!--                            <img src="../../assets/images/main/ptn/dowin.png" alt="">-->
<!--                        </span>-->
<!--                    </div>-->
<!--                    <div class="sub">-->
<!--                        <span style="cursor: pointer"  @click="openGame(HONOR_MICRO_GAMEID,HONOR_MICRO_VENDOR)">-->
<!--                            <img src="../../assets/images/main/ptn/micro.png" alt="">-->
<!--                        </span>-->
<!--                    </div>-->
<!--                    <div class="sub">-->
<!--                        <span style="cursor: pointer" @click="openGame(HONOR_SKYWIND_GAMEID,HONOR_SKYWIND_VENDOR)">-->
<!--                            <img src="../../assets/images/main/ptn/skywind.png" alt="">-->
<!--                        </span>-->
<!--                    </div>-->
<!--                    <div class="sub">-->
<!--                        <span style="cursor: pointer" @click="openGame(HONOR_PLAYTECH_GAMEID,HONOR_PLAYTECH_VENDOR)">-->
<!--                            <img src="../../assets/images/main/ptn/playtech.png" alt="">-->
<!--                        </span>-->
<!--                    </div>-->
                    <div class="sub">
                        <router-link :to="{path: '/casinohonor', query: {t: new Date().getTime(),type:2}}" tag="span">
                            <img src="../../assets/images/main/ptn/slot.png" alt="">
                        </router-link>
                    </div>

                </div>

                <div class="noticeandresult">
                    <div class="nt">
                        <div style="width: 100%;height: 42px;line-height: 42px;border-bottom: 2px solid #6c6c6c;padding-left: 10px;font-size: 18px;font-weight: bold;color: #343434;margin-bottom: 10px">
                            공지사항
                        </div>
                        <div v-for="item in noticeList" style="width: 100%;height: 40px;line-height: 40px;padding-left: 10px;font-size: 14px;;font-weight: bold">
                            <router-link style="cursor: pointer" tag="span" :to="{path: '/notice', query: {id:item.id,t: new Date().getTime()}}">
                                <i class="fa fa-warning" style="color: red"></i>
                                {{item.title}}
                            </router-link>
                        </div>
                    </div>
                    <div class="nt">
                        <div style="width: 100%;height: 42px;line-height: 42px;border-bottom: 2px solid #6c6c6c;padding-left: 10px;font-size: 18px;font-weight: bold;color: #343434;margin-bottom: 10px">
                            이벤트
                        </div>
                        <div v-for="item in eventList" style="width: 100%;height: 40px;line-height: 40px;padding-left: 10px;font-size: 14px;;font-weight: bold">
                            <router-link style="cursor: pointer" tag="span" :to="{path: '/event', query: {id:item.id,t: new Date().getTime()}}">
                                <i class="fa fa-gift" style="color: #e705f1"></i>
                                {{item.title}}
                            </router-link>
                        </div>
                    </div>
                    <div class="res">
                        <div
                                style="width: 100%;height: 42px;line-height: 42px;border-bottom: 2px solid #6c6c6c;padding-left: 10px;font-size: 18px;font-weight: bold;color: #343434;text-align: left;margin-bottom: 10px">
                            SPORTS RESULT
                        </div>
                        <div v-for="game in endgameList"
                             style="width: 100%;height: 40px;line-height: 40px;padding-left: 10px;font-size: 14px;;font-weight: bold;display: flex;justify-content: space-between;align-items: flex-start;text-align: left">
                            <div style="width: 10%">
                                 <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img src="../../assets/images/icon/football-ico.png"
                                                                                                           style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img src="../../assets/images/icon/baseball-ico.png"
                                                                                                            style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img src="../../assets/images/icon/basketball-ico.png"
                                                                                                              style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img src="../../assets/images/icon/volleyball-ico.png"
                                                                                                              style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img src="../../assets/images/icon/hockey-ico.png"
                                                                                                          style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img src="../../assets/images/icon/esport-ico.png"
                                                                                                           style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img src="../../assets/images/icon/tennis-ico.png"
                                                                                                          style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img src="../../assets/images/icon/usa-football-ico.png"
                                                                                                                style="width: 20px" alt=""></span>
                                <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BOXING"><img src="../../assets/images/icon/type/boxing.svg"
                                                                                                          style="width: 20px" alt=""></span>
                            </div>
                            <div style="width: 10%;text-align: left">
                                <img alt="" :src="game.league.imageUrl" style="max-width: 22px">
                            </div>
                            <div  style="text-align: left;width: 25%;overflow: hidden" v-html="game.homeTeamName">

                            </div>
                            <div style="width: 10%;text-align: center">
                                <span style="border-radius: 3px;border: 1px solid #a7a7a7;color: #ada09c;width: 35px;height: 35px;display: inline-block">{{game.homeTeamScore}}</span>
                            </div>
                            <div style="width: 10%;text-align: center;color:#ada09c ">
                                VS
                            </div>
                            <div style="width: 10%;text-align: center">
                                <span style="border-radius: 3px;border: 1px solid #a7a7a7;color: #ada09c;width: 35px;height: 35px;display: inline-block">{{game.awayTeamScore}}</span>
                            </div>
                            <div style="text-align: left;width: 25%;overflow: hidden;padding-left: 3px" v-html="game.awayTeamName">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sub_mobile">
<!--                    <mobile-menu-items-comp></mobile-menu-items-comp>-->
                    <right-bar-banner-comp></right-bar-banner-comp>

                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import {WEBSOCKET_URL} from "../../common/const";
    import {getEvent, getNoticeList} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyEndGames, getRecentlyGames} from "../../network/sportsRequest";
    import PopupComp from "../../components/PopupComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {getGameUrl2, getGameUrl3} from "../../network/casinoHonorRequest";

    export default {
        name: "Main",
        mixins: [postionMixin],
        components: {
            PopupComp,
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList: [],
                eventList: [],
                endgameList: [],
                noticePageSize: 8,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position: "메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer: [],
                base: [],
                basket: [],
                hokey: [],
                esports: [],
                vol: [],
                swiperOption: {
                    pagination: {el: '.swiper-pagination'},
                    autoplay: {},
                    disableOnInteraction: false,
                    delay: 2000,
                },
                HONOR_EVO_VENDOR : "evolution",
                HONOR_EVO_GAMEID : "evolution_baccarat_sicbo",

                HONOR_DREAM_VENDOR : "DreamGame",//CX
                HONOR_DREAM_GAMEID : "dgcasino",//CX

                HONOR_ASIAGAME_VENDOR : "Asia Gaming", //CX
                HONOR_ASIAGAME_GAMEID : "B001", //CX

                HONOR_PRAGMATIC_VENDOR : "PragmaticPlay Live",//CX
                HONOR_PRAGMATIC_GAMEID : "401",//CX

                HONOR_BOTA_VENDOR : "bota",//bo
                HONOR_BOTA_GAMEID : "bota",//bo


                HONOR_WM_VENDOR : "WM Live",//bo
                HONOR_WM_GAMEID : "wmcasino",//bo

                HONOR_DOWIN_VENDOR : "dowinn",//bo
                HONOR_DOWIN_GAMEID : "dowinncasino",//bo


                HONOR_MICRO_VENDOR : "MicroGaming Plus",//bo
                HONOR_MICRO_GAMEID : "SMG_titaniumLiveGames_MP_Baccarat",//bo

                HONOR_SKYWIND_VENDOR : "Skywind Live",//bo
                HONOR_SKYWIND_GAMEID : "sw_ro_spbac",//bo

                HONOR_PLAYTECH_VENDOR : "PlayTech",//bo
                HONOR_PLAYTECH_GAMEID : "ptx_ubal_lobby",//bo
            }
        },
        methods: {
            openGame(gameId, provider) {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getGameUrl3(gameId, provider).then(res => {
                    if (res.data.success) {
                        let url = res.data.data;
                        window.open(url, '_blank')
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                })
            },
        },
        created() {
            getRecentlyGames().then(res => {
                if (res.data.success) {
                    let recentlryGames = res.data.data
                    this.soccer = recentlryGames.soccer
                    this.basket = recentlryGames.bascket
                    this.base = recentlryGames.base
                    this.vol = recentlryGames.vol
                    this.hokey = recentlryGames.hockey
                    this.esports = recentlryGames.esports
                }
            })
            getRecentlyEndGames().then(res => {
                this.endgameList = res.data.data
            })
            getNoticeList(6).then(res=>{
                this.noticeList = res.data.data
            })
            getEvent(1, 6).then(res => {
                this.eventList = res.data.data
            })

        }
    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .main .main_img_mobile{
        display: none;
    }
    .main .sub_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }
    .main .sub_img .sub {
        width: 16%;
        cursor: pointer;
    }

    .main .sub_img img {
        width: 100%;
    }
    .main .sub_img img:hover {
        opacity: .7;
        transition: 0.4s;
    }

    .main .sub_mobile {
        display: none;
        width: 100%;
    }

    .main .mobile {
        display: none;
    }


    .noticeandresult{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 10px;
        color: #848484;
    }
    .noticeandresult .nt{
        width: 26%;
        text-align: left;
        padding: 0 10px;
    }
    .noticeandresult .res{
        width: 47%;
        text-align: right;
        padding: 0 10px;
    }
    .noticeandresult .res div{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    @media screen and (max-width: 1024px) {
        .main .main_img{
            display: none!important;
        }
        .main .main_img_mobile{
            display: flex;
        }
        .main .sub_img .sub {
            width: 49.5% !important;
            padding: 2px 2px;
            margin-top: 5px;
        }

        .main .sub_mobile {
            display: block !important;
        }

        .main .pc {
            display: none !important;
        }

        .main .mobile {
            display: block !important;
        }
        .noticeandresult{
            width: 100%;
            flex-wrap: wrap;
        }
        .noticeandresult .nt{
            width: 100% !important;
        }
        .noticeandresult .res{
            width: 100% !important;
            margin-top: 20px;

        }
        .noticeandresult .res div{
            font-size: 11px!important;
        }
    }


</style>
